<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>用户管理</span></div>
						<div>
							<el-button size="small" type="primary" @click="bind">绑定微信登录</el-button>
							<el-button size="small" type="primary" @click="add">添加</el-button>
						</div>
					</div>
					<template v-if="list.length > 0">
						<el-table :data="list" class="form-table" border style="width: 100%">
							<el-table-column prop="name" label="用户名"></el-table-column>
							<el-table-column prop="nickName" label="昵称" ></el-table-column>
							<el-table-column prop="phone" label="手机号"></el-table-column>
							<el-table-column prop="registrationTime" label="创建时间" ></el-table-column>
							<el-table-column fixed="right" label="操作" width="250">
								<template slot-scope="scope">
									<a href="javascript: void(0);" class="operate-btn" @click="edit(scope.row._id)"><i class="i-ico i-edit"></i><span>编辑</span></a>
									<a href="javascript: void(0);" class="operate-btn" @click="editPwd(scope.row._id)"><i class="i-ico i-edit"></i><span>编辑密码</span></a>
									<a href="javascript: void(0);" class="operate-btn" @click="del(scope.row._id)"><i class="i-ico i-delete"></i><span>删除</span></a>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
					</template>
					<noData v-else text="暂时还没有数据哦！"></noData>
				</div>
				<el-dialog :title="form._id?'编辑用户':'添加用户'" :visible.sync="dialogVisible" width="600px">
					<el-form :model="form" ref="form" :rules="rules" label-width="80px">
						<el-form-item label="昵称" prop="nickName">
							<el-input v-model="form.nickName" placeholder="请输入昵称"></el-input>
						</el-form-item>
						<el-form-item label="头像" prop="headPortrait">
							<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" :show-file-list="false" :before-upload="beforeImgUpload" :on-success="handleImgSuccess">
								<img v-if="form.headPortrait" :src="form.headPortrait" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="账号" prop="name">
							<el-input v-model="form.name" placeholder="请输入账号" v-if="!form._id"></el-input>
							<el-input v-model="form.name" placeholder="请输入账号" readonly v-else></el-input>
						</el-form-item>
						<template v-if="!form._id">
							<el-form-item label="密码" prop="passwd">
								<el-input v-model="form.passwd" type="password" placeholder="请输入密码"></el-input>
							</el-form-item>
							<el-form-item label="确认密码" prop="confirmPasswd">
								<el-input v-model="form.confirmPasswd" type="password" placeholder="请输入确认密码"></el-input>
							</el-form-item>
						</template>
						<el-form-item label="手机号" prop="phone">
							<el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="职业" prop="job">
							<el-input v-model="form.job" placeholder="请输入职业"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="save('form')">保存</el-button>
						<el-button @click="cancel">取消</el-button>
					</div>
				</el-dialog>
				<el-dialog title="修改密码" :visible.sync="pwdVisible" width="600px">
					<el-form :model="pwdForm" ref="pwdForm" :rules="pwdRules" label-width="80px">
						<el-form-item label="账号" prop="name">
							<el-input v-model="pwdForm.name" readonly></el-input>
						</el-form-item>
						<el-form-item label="旧密码" prop="oldPass">
							<el-input v-model="pwdForm.oldPass" type="password" placeholder="请输入旧密码"></el-input>
						</el-form-item>
						<el-form-item label="新密码" prop="passwd">
							<el-input v-model="pwdForm.passwd" type="password" placeholder="请输入新密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="confirmPasswd">
							<el-input v-model="pwdForm.confirmPasswd" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="savePwd('pwdForm')">保存</el-button>
						<el-button @click="cancelPwd">取消</el-button>
					</div>
				</el-dialog>
				<el-dialog  title="请使用微信扫描进行绑定" :visible.sync="bindVisible" width="300px">
					<div class="scan-preiview">
						<!-- <div class="title">微信扫一扫</div> -->
						<div class="code_img" ref="qrCodeUrl"></div>
					</div>
				</el-dialog>
				
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
import QRCode from 'qrcodejs2';
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			list:[],
			listParams:{
				pageNo:1,
				pageSize:20
			},
			count: 0,
			dialogVisible:false,
			form:{},
			rules:{
				nickName:[
					{required:true,message:'请输入昵称！',trigger:'blur'}
				],
				name:[
					{required:true,message:'请输入账号！',trigger:'blur'}
				],
				passwd:[
					{required:true,message:'请输入密码！',trigger:'blur'}
				],
				confirmPasswd:[
					{required:true,message:'请输入确认密码！',trigger:'blur'}
				],
				phone:[
					{required:true,message:'请输入手机号！',trigger:'blur'}
				],
				job:[
					{required:true,message:'请输入职业！',trigger:'blur'}
				],
			},
			pwdVisible:false,
			pwdForm:{
				id:1,
				name:'',
				imageUrl:'',
				remarks:'',
			},
			pwdRules:{
				oldPass:[
					{required:true,message:'请输入旧密码！',trigger:'blur'}
				],
				passwd:[
					{required:true,message:'请输入新密码！',trigger:'blur'}
				],
				confirmPasswd:[
					{required:true,message:'请输入确认密码！',trigger:'blur'}
				],
			},
			bindVisible:false
		}
	},
	created(){
		
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getList();
	},
	methods: {
		// 获取模板列表
		getList(){
			const _this = this;
			get('/formadmin/user/v_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getList();
		},
		// 上传前校验文件大小 不能超过5M
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < 5;
			if (!isLt5M) {
				this.$message.error("大小不能超过 5MB!");
			}
			return isLt5M;
		},
		// 上传页眉成功
		handleImgSuccess(file){
			if(file.code == 200){
				this.form.headPortrait = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 添加
		add(){
			const _this = this;
			_this.dialogVisible = true;
			_this.form = {
				nickName:'',
				name:'',
				headPortrait:'',
				passwd:'',
				confirmPasswd:'',
				phone:'',
				job:'',
			};
			_this.$nextTick(()=>{
				_this.$refs['form'].clearValidate();
			})
		},// 编辑
		edit(id){
			const _this = this;
			get('/formadmin/user/v_edit.jhtml',{id}).then(res => {
				if(res.code == 200){
					_this.form = res.data.bean;
					_this.dialogVisible = true;
					_this.$nextTick(()=>{
						_this.$refs['form'].clearValidate();
					})
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 取消
		cancel(){
			const _this = this;
			_this.dialogVisible = false;
		},
		// 保存
		save(form){
			const _this = this;
			let url = '';
			if(_this.form._id){
				url = '/formadmin/user/o_update.jhtml';
				_this.form = {
					..._this.form,
					id:_this.form._id
				}
			}else{
				url = '/formadmin/user/o_save.jhtml'
			}
			_this.$refs[form].validate(valid=>{
				if(valid){
					post(url,_this.form).then(res => {
						if(res.code == 200){
							_this.dialogVisible = false;
							_this.getList();
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		// 编辑密码
		editPwd(id){
			const _this = this;
			get('/formadmin/user/edit_password.jhtml',{id}).then(res => {
				if(res.code == 200){
					let bean = res.data.bean;
					_this.pwdForm = {
						id:bean._id,
						name:bean.name,
						oldPass:'',
						passwd:'',
						confirmPasswd:'',
					}
					_this.pwdVisible = true;
					_this.$nextTick(()=>{
						_this.$refs['pwdForm'].clearValidate();
					})
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 取消
		cancelPwd(){
			const _this = this;
			_this.pwdVisible = false;
		},
		// 保存
		savePwd(form){
			const _this = this;
			_this.$refs[form].validate(valid=>{
				if(valid){
					post('/formadmin/user/update_password.jhtml',_this.pwdForm).then(res => {
						if(res.code == 200){
							_this.pwdVisible = false;
							_this.getList();
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		// 删除
		del(id){
			const _this = this;
			this.$confirm('确定删除吗？删除后不可恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/user/o_delete.jhtml',{id}).then(res => {
					if(res.code == 200){
						_this.getList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
		bind(){
			this.bindVisible = true;
			
			this.$nextTick(()=>{
			if(this.$refs.qrCodeUrl.innerHTML != ''){
				this.$refs.qrCodeUrl.innerHTML = ""
			}
				this.creatQrCode();
			})
			
		},
		creatQrCode () {
			const _this = this;
			let url = '';
			get('/formadmin/user/getWxQrcode.jhtml',{}).then(res => {
				if(res.code == 200){
					url = window.location.origin + res.data.qrcodeUrl;
					new QRCode(this.$refs.qrCodeUrl, {
						text: url,
						width: 260,
						height: 260,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.L
					})
				}
			}).catch(err => {
				_this.$message.error(err);
			})
			// const url = window.location.origin + '/formadmin/user/getWxQrcode.jhtml';
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
	}
	.form-table{
		.img{
			width:200px;
			height: 100px;
		}
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-delete{background-position: -87px 2px;}
			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			overflow-y: auto;
			.el-form-item:last-child{
				margin-bottom: 0;
			}
			.avatar-uploader{
				width:100px;
				height: 100px;
				border:1px solid #DCDFE6;
					text-align: center;
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width:100%;
					height: 100%;
					line-height:100px;
				}
				.avatar {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>